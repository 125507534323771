
<template>
  <b-container class="bg-white ee-content">
    <!-- <b-card-group deck>
      <iris-appointment-block v-bind="appointmentBlockProps" />
    </b-card-group> -->
    <spinner v-if="!loaded && !wrapperLoaded" class="my-4" />
    <ClientOnly>
      <IrisWrapper v-if="loaded" v-bind="injectedVariables" />
      <b-modal id="logoutConfirmation" :ok-disabled="logoutProcessing || (eraseAllDataOnLogout && !surnameMatches)" :ok-variant="eraseAllDataOnLogout ? 'danger' : 'primary'" title="IRIS Logout confirmation" :ok-title="eraseAllDataOnLogout ? 'Logout and erase IRIS session' : 'Logout'" @ok="performLogout">
      <p>Do you want to leave IRIS?</p>
      <b-form-checkbox v-model="eraseAllDataOnLogout" class="text-right">Erase IRIS session data?</b-form-checkbox>
      <template v-if="eraseAllDataOnLogout && familyLastName">
          <p>Deleting any unsaved session data in IRIS is irreversible. Enter the family's last name
        (<code>{{familyLastName}}</code>) below to confirm you want to permanently erase your IRIS session: </p>
        <b-form-input v-model.trim="surnameEntered" />
      </template>
    </b-modal>
  </ClientOnly>
  </b-container>
</template>
      
<style scoped>

.ee-content {
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

</style>
<script lang="ts" setup>

import type { IrisStore, IrisState } from '@iris/store/types'
import type { IRegModelParam } from '@iris/regmodel'
import Spinner from 'vue-spinner/src/RiseLoader.vue'
import { DeleteSubscriberRecordsDocument, DeleteSubscriberRecordsMutation, DeleteSubscriberRecordsMutationVariables, TodaysPresentationsDocument, TodaysPresentationsQuery, TodaysPresentationsQueryVariables } from '~~/iris/src/queries'
import { BvModalEvent } from 'bootstrap-vue'
import { ApolloClient } from '@apollo/client/core'
import moment from 'moment-timezone'
import { TIMEZONE } from '~~/iris/src/constants'

const PRICECODE = 'ESTIALABS'

const eraseAllDataOnLogout = ref(false)

const logoutProcessing = ref(false)
const surnameEntered = ref<string>()

const surnameMatches = computed(() => !familyLastName.value || familyLastName.value === surnameEntered.value)

const familyLastName = computed(() => injectedVariables.store?.state.familyInformation.lastName)


const router = useRouter()

const performLogout = async (evt: BvModalEvent) => {
  evt.preventDefault()
  logoutProcessing.value = true
  if (eraseAllDataOnLogout.value) {
    if (injectedVariables.store.state.createAccountResult && injectedVariables.store.state.step !== 14) { // STEP_CHOOSE_SESSIONS
      // schedule account to be removed
      await injectedVariables.store.nestApi.apolloClient().mutate<DeleteSubscriberRecordsMutation, DeleteSubscriberRecordsMutationVariables>({
        mutation: DeleteSubscriberRecordsDocument,
        variables: {
          subscriberId: injectedVariables.store.state.createAccountResult.subscriberId
        }
      })
    }
    localStorage.removeItem('iris-lite-data')
  }
  logoutProcessing.value = false
  instance.$bvModal.hide('logoutConfirmation')
  return router.push({
      name: 'logout',
      params: {
        prompt: 'false'
      }
    })
}

export interface IrisRegModel {
  regHitId: number
  regModel: IRegModelParam[]
  regCode: string
  instCode: string
  consultantDisplayName: string
  consultantUserId: number
  consultantOffice: string
}

type IrisInitPartial = {
  familyInformation?: Partial<IrisState['familyInformation']>
  address?: Partial<Pick<IrisState['address'], 'state' | 'postcode'>>
  children?: IrisState['children']
  assessmentId?: IrisState['assessmentId']
  timeDifference: IrisState['timeDifference']
  finance?: Partial<Pick<IrisState['finance'], 'pricingDate'>>
}

const { $axios, $REGISTRATION_URL } = useNuxtApp()
const { public: {
  irisDocumentsBaseUrl,
  irisBackendUrl,
  loqateApiKey
} } = useRuntimeConfig()

const injectedVariables = reactive<Partial<import('@/iris/IrisWrapper.vue').PropsType>>({
  backLink: {
    name: 'index'
  },
  feathers: undefined,
  irisApi: undefined,
  loqateApi: undefined,
  param: undefined,
  store: undefined
})

const wrapperLoaded = ref(false)

const loaded = computed<boolean>(() => {
  return !!(
    injectedVariables.store &&
    injectedVariables.loqateApi &&
    injectedVariables.irisApi &&
    injectedVariables.param &&
    injectedVariables.feathers
  )
})

const instance  = getCurrentInstance().proxy

onBeforeRouteLeave((to, from, next) => {
  if (to.name === 'logout' && to.params.prompt !== 'false') {
    instance.$bvModal.show('logoutConfirmation')
    return next(false)
  }
  return next()
})

const presentationsCheck = (client: ApolloClient<any>) => {
  return client.query<TodaysPresentationsQuery, TodaysPresentationsQueryVariables>({
    query: TodaysPresentationsDocument,
    variables: {
      limitToOwn: true,
      start: moment.tz(TIMEZONE).subtract({ days: 30 }).startOf('day').toISOString(),
      end: moment.tz(TIMEZONE).subtract({ days: 1 }).endOf('day').toISOString()
    },
    fetchPolicy: 'no-cache'
  }).then(result => {
    if (result.data.appointments.length > 0) {
      return instance.$bvModal.msgBoxOk(`You have ${result.data.appointments.length} presentations from previous days which haven't been outcomed please fill these in first`, {
        okTitle: 'Outcome Presentations',
        noCloseOnBackdrop: true,
        title: 'Outstanding Presentations',
      }).then(() => {
        return router.replace({
          name: 'iris-presentations',
          query: {
            outstanding: '1'
          }
        })
      })
    }  
  })
}

/** setup initial env for iris */
const setupData = async () => {
  try {

    // TODO BYPASS THIS IF THE  SESSION IS IN LOCAL STORAGE!!!!!!!!!!!!!
    const { default: LoqateApi } = await import('@iris/loqateApi')
    const { clientWithOptions } = await import('@iris/feathersjs')
    const { default: parseRegModel } = await import('@iris/regmodel')
    const { default: irisStore } = await import('@iris/store/index')
    const { default: IrisApi } = await import('@iris/irisApi')
    const { default: moment } = await import('moment-timezone')

    injectedVariables.loqateApi = new LoqateApi({ Key: loqateApiKey })

    injectedVariables.feathers = clientWithOptions({
      axios: $axios,
      url: irisBackendUrl
    })


    const createIrisApi = (store: IrisStore) => {
      const irisApi = new IrisApi(`${$REGISTRATION_URL}/iris`, store.state.regHitId, {
        axios: $axios,
        priceCode: PRICECODE
      })
      injectedVariables.irisApi = irisApi
      return irisApi
    }

    if (localStorage.getItem('iris-lite-data')) {
      injectedVariables.store = irisStore({
        irisDocumentsBucket: irisDocumentsBaseUrl,
        createIrisApi,
      })
    } else {
      // new session
      /** initial request date */
      const localRequestDate = new Date()
      /** clock time difference */
      let timeDifference = 0
      const data = await $axios.post<IrisRegModel>(`${$REGISTRATION_URL}/api/iris/newSession`, {
        priceCode: PRICECODE
      }).then(({ data, headers: { date } }) => {
        if (date) {
          timeDifference = moment(date).diff(localRequestDate, 'seconds') * 1000
        }
        return data
      })


      const initialStoreState: IrisInitPartial = {
        timeDifference
      }
      injectedVariables.store = irisStore({
        irisDocumentsBucket: irisDocumentsBaseUrl,
        regHitId: data.regHitId,
        regModelMap: parseRegModel(data.regModel),
        consultant: data.consultantDisplayName,
        userId: data.consultantUserId,
        branchOffice: data.consultantOffice,
        createIrisApi,
        initialStoreState: (initialStoreState as unknown) as IrisState
      })

    }
    injectedVariables.store.$feathers = injectedVariables.feathers!
    injectedVariables.param = (name: string) => injectedVariables.store.state.regModelMap[name]

    presentationsCheck(injectedVariables.store.nestApi.apolloClient())


  } catch (e) {
    if (e instanceof Error) {
      console.log(e.name)
      console.log(e.message)
      console.log(e.cause)
      console.log(e.stack)
    } else {
      console.error(e)
    }
    // debugger
    throw e
  }

}

if ((process as any).client) {
  onMounted(setupData)
}

const IrisWrapper = () => {
  if (!(process as any).client) {
    return {} // return empty component for server
  }
  console.log('grabbing wrapper')
  return import('@/iris/IrisWrapper.vue').then((e) => {
    console.log('wrapper loaded')
    wrapperLoaded.value = true
    return e
  }).catch(e => {
    if (e instanceof Error) {
      console.log(e.name)
      console.log(e.message)
      console.log(e.cause)
      console.log(e.stack)
    } else {
      console.error(e)
    }
    // debugger
    throw e
  })
}


// const IrisWrapper = () => {
//   if (process.client) {
//     console.log('importing async component')
//     return import('@/iris/IrisWrapper.vue').then(r => {
//       console.log('mported!!!!!')
//       console.log(r)
//       return r.default
//     }).catch(e => {
//       console.log('exception!!!')
//       console.log(e)
//       throw e
//     })
//   }
// }

// import { EventDefinition } from 'node-calendly/lib/endpoints/types/Event'


// const events = ref<any[]>([])

// const { proxy: { $fetch } }  = getCurrentInstance()!

// const getUuidFromUri = 


// const { data } = useFetch<EventDefinition[]>('/api/events/jeffrey@borgs.net')


// const appointmentBlockProps: AppointmentBlockProps = {
//   email: 'jeffrey@borgs.net',
//   firstName: 'jeff',
//   lastName: 'borg',
//   interval: Interval.after(DateTime.now().plus({ week: 1 }).startOf('day'), { weeks: 4 }),
//   slotsAvailable: 10,
//   subscriberId: 1234
// }

// console.log(appointments)

// const { data } = useLazyFetch('/api/event/:id', {

// })



</script>
      
